<script setup>
import { ref, onMounted} from 'vue'
import handelServerData from '../services/handelServerData'
import processData from '../Data/createClientData'
import CategoryCard from '../components/CategoryCard.vue'
import createBeitraegeList from '../Data/datahandling/createBeitraegeList'
import BeitragListe from '../components/BeitragListe.vue'

const clientData = ref(null)
const beitraege = ref(null)
var modePartyIsOver = false

onMounted(() => {
  handelServerData.getClientData()
    .then((response) => {
        clientData.value = processData(response.data)
        beitraege.value= createBeitraegeList(response.data.beitrag)
    })
    .catch((error) => {
        console.log(error)
    })
  handelServerData.getClientConfig()
    .then((response) => {
      console.log(response.data)
        modePartyIsOver = '1' == response.data.partyIsOver
    })
    .catch((error) => {
        console.log(error)
    })
})
</script>

<template>
    <div v-if="modePartyIsOver">
      <h1>Das war ein großartiges Buffet!</h1>
      <p>Hier noch mal alles, was dabei war.</p>
    </div>
    <div v-else>
      <h1>Was möchtest Du als Beilage für das Buffet mitbringen?</h1>
      <p>Wir werden Grillen (auch etwas vegetarisches Zeugs wird da sein), und wir werden zwei Suppen machen (vegatarisch und nicht vegetarisch).</p>
      <p>Wenn Du einen Button klickst bekommst Du weitere Vorschläge angezeigt. Dabei zeigen wir nur Vorschläge an, die noch nicht (zu oft) ausgewählt wurden.</p>
      <div class="categories">
        <CategoryCard v-for="category in clientData" v-bind:category="category" :key="category.data"/>
      </div>
      <p>Vor Ort ist ein Backofen, wenn also was frisch gemacht werden soll und nicht zu lange dauert können wir das gerne vor Ort machen.</p>
      <p>Bitte plant von der Menge nur so viel bzw. wenig, dass nichts weggeworfen werden muss. Denn jeder wird irgendwas 
        mitbringen, und wir können die Reste auch nicht verwerten, da Urlaub bevorsteht (bringt also bitte Tupper mit ;-))</p>
    </div>
    <div class="beitraege">
      <BeitragListe :beitraege="beitraege"></BeitragListe>
    </div>
</template>

<style scoped>
.categories {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
<script>
  import DetailCard from '../components/DetailCard.vue' //kann man ieventuell noch importieren
  export default {
    props: {
      categoryDetails: {
        type: String, // Beachten Sie, dass wir hier den Typ auf String ändern
        required: true,
      },
      category: {
        type: String,
        required: true
      }
    },
    components: {
      DetailCard
    }
  }
</script>

<template>
  <h1>Details in der Kategorie "{{ category }}"</h1>
  <div class="details">
    <DetailCard v-for="detail in JSON.parse(categoryDetails)" :key="detail" :detail="detail" :category="category"/>
  </div>
</template>

<style scoped>
.details {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.event-card {
  padding: 20px;
  width: 250px;
  cursor: pointer;
  border: 1px solid #39495c;
  margin-bottom: 18px;
}
.event-card:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
}
.event-link {
  color: #2c3e50;
  text-decoration: none;
}
</style>
import {calclulateFactor, adjustMaxPoints } from './logic.js';

export function updateCount(details, beitrag) {
    let updatedDetails = details;

    beitrag.forEach(item => {
        const currentCategory = item.category;
        const currentDetail = item.detail;

        const gefundeneKategorie = updatedDetails.find(entry => {
            return entry.category === currentCategory;
        });

        if (gefundeneKategorie) {
            const indexOfCategory = updatedDetails.findIndex(entry => entry.category === currentCategory);
            const gefundenesDetail = updatedDetails[indexOfCategory].details.find(detail => detail.name === currentDetail);

            if (gefundenesDetail) {
                const indexOfDetail = updatedDetails[indexOfCategory].details.indexOf(gefundenesDetail);
                updatedDetails[indexOfCategory].details[indexOfDetail].count += 1;
            } else {
                console.log('Kein passendes Detail in der Kategorie gefunden');
            }
        } else {
            console.log('Kein Element gefunden.');
        }
    });
    //console.log('Die Details der gewählten Kategorie sehen wie folgt aus:', updatedDetails[0].details);
    return updatedDetails;
}

export function updateMaxPoints(details, blockedList){
    let updatedDetails = details
    let firstBlockedList = blockedList
    let factorList = []
    let finalDetails= []

    if ((firstBlockedList.length/updatedDetails.length) > 0.25) {
        factorList = calclulateFactor(updatedDetails);
        //console.log('Hier die Faktorliste:',factorList);

        finalDetails = adjustMaxPoints(updatedDetails, factorList);
        //console.log('Ausgangsdaten von updateMaxPoints:',updatedDetails)
        //console.log('Daten zum erstellen der Clientdaten:',finalDetails);
        return finalDetails
    } else {
        return updatedDetails
    }
}


/*
module.exports = {
    updateCount,
    updateMaxPoints
}
*/
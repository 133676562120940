export function isCategoryAllowed(categoryName,updatedDetails) {  

    if(checkPoints(categoryName,updatedDetails) && ableToAddADetail(categoryName,updatedDetails)) {
        return true;
    } else {
        return false
    }
}

function checkPoints(categoryName,updatedDetails){
    const categoryToCheck = updatedDetails.find(item => item.category === categoryName);
    const maxPoints = categoryToCheck.maxPoints;
    var currentPoints = calculateCurrentPoints(categoryName,updatedDetails);
    if(currentPoints < maxPoints) {
        return true;
    } else {
        return false
    }
}

function ableToAddADetail(categoryName,updatedDetails) {
    const categoryToCalculate = updatedDetails.find(item => item.category === categoryName);
    const eligibleDetails = categoryToCalculate.details.filter(detail => detail.count < detail.maxCount);
    if (eligibleDetails.length === 0) {
        return false;
    }

    const smallestValue = Math.min(...eligibleDetails.map(detail => detail.value));

    const currentPoints = calculateCurrentPoints(categoryName,updatedDetails);

    if (currentPoints + smallestValue <= categoryToCalculate.maxPoints) {
        return true;
    } else {
        return false;
    }
}

export function calculateCurrentPoints(categoryName,updatedDetails) {
    const categoryToCalculate = updatedDetails.find(item => item.category === categoryName);
    var currentPoints = 0;
    currentPoints = 0
    categoryToCalculate.details.forEach(detail => {
        currentPoints = currentPoints + (detail.count * detail.value)
    });
    return currentPoints;
}

export function isDetailAllowed(category, detailName, detailData) {
    const categoryToCheck = detailData.find(item => item.category === category);

    if (categoryToCheck) {
        const detailToCheck = categoryToCheck.details.find(detail => detail.name === detailName);
        if (detailToCheck) {
            if (detailToCheck.count < detailToCheck.maxCount) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } else {
        return false;
    }
}

//Hier kommt nun die logic für die maxPoints anpassung
export function calclulateFactor(details){
    const factorList = [];
    details.forEach(category =>{
        if(category.category !== 'Eigene Ideen'){
            let currentPointsofCategory = 0;
            category.details.forEach(detail =>{
                currentPointsofCategory = currentPointsofCategory + (detail.count * detail.value)
            })
            const multiplicator = currentPointsofCategory/category.maxPoints
            let factor = multiplicator
            if(factor < 1){
                factor = 1
                factorList.push(
                    {category: category.category, factor: factor}
                )
            }else{
                factorList.push(
                    {category: category.category, factor: factor}
                )
            }
        }
    })
    //console.log('Factorliste aus der logic-Funktion calculateFactor',factorList)
    return factorList;
}

export function adjustMaxPoints(details, factorList) {
    const updatedDetails = JSON.parse(JSON.stringify(details));  //erstellt eine kopie von details, ohne das orginal zu verändern

    // Sortiere die factorList nach Faktoren in aufsteigender Reihenfolge
    const sortedFactors = factorList.sort((a, b) => a.factor - b.factor);

    // Wähle die beiden Kategorien mit den niedrigsten Faktoren aus
    const lowestFactors = sortedFactors.slice(0, 2);
    //console.log(lowestFactors);

    lowestFactors.forEach((factorObj) => {
        const category = updatedDetails.find((d) => d.category === factorObj.category);
        let multiplicator;

        if (Number.isInteger(factorObj.factor)) {
            multiplicator = factorObj.factor + 1;
        } else {
            multiplicator = Math.ceil(factorObj.factor);
        }

        // Dynamische Anpassung der Maximalpunktzahl
        category.maxPoints *= multiplicator;
        category.details.forEach((detailObj) => {
            detailObj.maxCount *= multiplicator;
        });
    });

    //console.log(updatedDetails[2].details)

    return updatedDetails;
}
import axios from 'axios'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL || 'http://127.0.0.1:8080',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export default {
  getClientData() {
    return apiClient.get('/getDataItems')
  },
  getClientConfig() {
    return apiClient.get('/getConfig')
  },
  postCientData(data) {
    return apiClient.post('/addInfo', data)
  }
}
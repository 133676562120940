<script>
import { RouterLink } from 'vue-router';
export default {
  props: {
    detail: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true
    }
  },
  components: {
    RouterLink
  }
}
</script>

<template>
  <RouterLink 
    class="form-link"
    :to="{ name: 'submit', params: { choosenCategory:category, choosenDetail:detail }}">
    <div class="event-card">
        <h3>{{ detail }}</h3>
        <!-- <p>{{ category }}</p> -->
    </div>
  </RouterLink>
</template>

<style scoped>
.event-card {
  padding: 20px;
  width: 250px;
  cursor: pointer;
  border: 1px solid #39495c;
  margin-bottom: 18px;
}
.event-card:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
}
.event-link {
  color: #2c3e50;
  text-decoration: none;
}
.form-link {
  color: #2c3e50;
  text-decoration: none;
}
</style>
import { isCategoryAllowed, isDetailAllowed} from './logic.js';

export function createBlockedList(updatedDetails) {
    let blockedData = [];
    for (const category of updatedDetails) {
        if (!isCategoryAllowed(category.category,updatedDetails)) {
            blockedData.push(category.category);
        }
    }
    return blockedData;
}

export function createClientDataFunction(finalDetails){
    const detailData = finalDetails;
    let clientData = []

    for (const category of detailData) {
        if (isCategoryAllowed(category.category, detailData)) {
            clientData.push({
                category: category.category,
                details: []
            });

            for (const detail of category.details) {
                if (isDetailAllowed(category.category, detail.name, detailData)) {
                    const categoryToUpdate = clientData.find(item => item.category === category.category);
                    if (categoryToUpdate) {
                        categoryToUpdate.details.push(detail.name);
                    } else {
                        console.error(`Category '${category.category}' not found.`);
                    }
                } else {
                    console.error(`Detail '${detail.name}' not allowed for category '${category.category}'.`);
                }
            }
        } else {
            console.error(`Kategorie ${category.category} erfüllt die Kriterien nicht, da entweder die max Punkte erreicht wurden oder kein Detail mehr auszuwählen ist`)
        }
    }
    return clientData
}


/*
module.exports={
    createBlockedList,
    createClientDataFunction
}
*/
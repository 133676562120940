<template>
    <!-- <p>Hier kommen die Beiträge hin</p>
    <p>{{ JSON.stringify(beitraege) }}</p> -->
    <div v-if="beitraege" class="flex-container">
        <div class="gerichte">
            <h2>Aus Vorschlägen gewählt:</h2>
            <ul>
            <div v-for="gericht in beitraege.Gerichte" :key="gericht.detail">
                <li>
                    <h4>{{ gericht.count }} x {{ gericht.detail }}</h4>
                </li>
            </div>
            </ul>
        </div>
        <div class="ideen">
            <h2>Eure Eigene Ideen:</h2>
            <ul>
            <div v-for="idee in beitraege.Ideen" :key="idee">
                <li>
                    <h4>{{ idee }}</h4>
                </li>
            </div>
            </ul>
        </div>
    </div>
</template>
  
<script>
  export default {
    props: {
        beitraege: {
          type: Object,
          required: true,
      },
    },
  }
</script>
  
<style scoped>

.flex-container {
    display: flex;  
    justify-content: center;
    margin: 5rem;
}

.gerichte,
.ideen {
    margin-right: 20px; /* Optional: Füge einen Abstand zwischen den Divs hinzu */
    padding: 20px;
    width: 250px;
    cursor: pointer;
    border: 1px solid #39495c;
    margin-bottom: 18px;
    text-align: left;
}
.gerichte,
.ideen h2{
    text-align: left;
}

.custom-list {
  list-style: none; /* Entfernt die standardmäßigen Aufzählungspunkte */
}

.custom-list li:before {
  content: '•'; /* Setzt einen individuellen Aufzählungspunkt, z.B., ein Punkt */
  color: #ff0000; /* Ändert die Farbe des Aufzählungspunkts */
  margin-right: 5px; /* Fügt einen Abstand zwischen dem Punkt und dem Inhalt hinzu */
}


</style>

.flex-container {
    display: flex;  
    justify-content: center;
    margin: 5rem;
}

.gerichte,
.ideen {
    margin-right: 20px; /* Optional: Füge einen Abstand zwischen den Divs hinzu */
    padding: 20px;
    width: 250px;
    cursor: pointer;
    border: 1px solid #39495c;
    margin-bottom: 18px;
}
.gerichte,
.ideen h2{
    text-align: left;
}
  
<script>
import { RouterLink } from 'vue-router';
export default {
  props: {
    category: {
    type: Object,
    required: true,
  },
  },
  components: {
    RouterLink
  }
}
</script>

<template>
  <RouterLink 
    class="category-link"
    :to="{ name: 'details', params: { category:category.category, categoryDetails: JSON.stringify(category.details) }}">
    <div class="category-card">
      <h2>{{category.category }}</h2>
    </div>
  </RouterLink>
</template>

<style scoped>
.category-card {
  padding: 20px;
  width: 250px;
  cursor: pointer;
  border: 1px solid #39495c;
  margin-bottom: 18px;
}
.category-card:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
}
.category-link {
  color: #2c3e50;
  text-decoration: none;
}
</style>
/* //Importieren der Funktion um Details-Daten anzupassen
const importedDatahandling = require('./datahandling/updateDetails')
//Importieren der Funktionen zur festlegung, ob Kategorie und Detail 
const importedLists = require('./datahandling/createData') */

// Beispiel für korrekte relative Pfade
import { updateCount, updateMaxPoints } from './datahandling/updateDetails.js';
import { createBlockedList, createClientDataFunction } from './datahandling/createData.js';

function processData(loadedData) {
    // Zwischenspeichern der Daten aus der Datenbank
    const detailsData = loadedData.details
    const beitragData = loadedData.beitrag
    //console.log('Die importierten Daten sehen wie folgt aus:',{detailsData, beitragData});

    // Hier können Sie Ihre Datenverarbeitungslogik implementieren
    //Funktion zum erstellen der Counts in den Details anhand der Beiträge
    //const updatedDetails = importedDatahandling.updateCount(detailsData, beitragData);
    const updatedDetails = updateCount(detailsData, beitragData);
    //console.log(updatedDetails);
    //console.log(updatedDetails[0].details);
    //console.log(updatedDetails[3].details);

    //Anhand der eingefügten Counts wird eine BlockedList erstellet
    //const firstBlockedList = importedLists.createBlockedList(updatedDetails);
    const firstBlockedList = createBlockedList(updatedDetails);
    //console.log('Hier ist die erste Blocked List anhand von CountUpdate', firstBlockedList);

    //MaxPoints der Details updaten und deren maxCounts
    //const finalDetails = importedDatahandling.updateMaxPoints(updatedDetails,firstBlockedList);
    const finalDetails = updateMaxPoints(updatedDetails,firstBlockedList);
    //console.log('Dies sind die Daten, die der zur bestimmung der Clientdaten verwendet werden',finalDetails);

    //const clientData = importedLists.createClientData(finalDetails)
    const clientData = createClientDataFunction(finalDetails)
    //console.log('Ich bin der Datensatzt für den CLient',clientData)
    return clientData
}

export default processData

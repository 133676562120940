import { createRouter, createWebHistory } from 'vue-router'
import AuswahlListView from '../views/AuswahlListView.vue'
import InfoView from '../views/InfoView.vue'
import AuswahlDetailView from '../views/AuswahlDetailView.vue'
import SubmitFormView from '../views/SubmitFormView.vue'
import SubumitSuccesfullView from '../views/SubumitSuccesfullView.vue'
import SubmitFailedView from '../views/SubmitFailedView.vue'

const router = createRouter({
  history: createWebHistory(),
//  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'auswahl',
      component: AuswahlListView
    },
    {
      path: '/details/:category/:categoryDetails',
      name: 'details',
      component: AuswahlDetailView,
      props: true
    },
    {
      path: '/info',
      name: 'info',
      component: InfoView
    },
    {
      path: '/submit/:choosenCategory/:choosenDetail',
      name: 'submit',
      component: SubmitFormView,
      props: true,
    },
    {
      path: '/successful',
      name: 'success',
      component: SubumitSuccesfullView
    },
    {
      path: '/failed',
      name: 'fail',
      component: SubmitFailedView
    }
  ]
})

export default router
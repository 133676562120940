function createBeitraegeList(beitraege) {
    let beitraegeList = []
    let eigeneIdeen = []
    for(const beitrag of beitraege) {
        //console.log(beitrag.detail)
        if(beitrag.detail !== 'Denkt euch was aus'){
            if(!checkIfElementExistInArray(beitrag, beitraegeList)){
                beitraegeList.push({
                    detail: beitrag.detail,
                    count: 1
                })
            } else {
                const gefundenesElement = beitraegeList.find(item => item.detail === beitrag.detail)
                if(gefundenesElement) {
                    gefundenesElement.count += 1
                } else {
                    console.log('Count konnte nicht erhöht werden')
                }
            }
        } else {
            eigeneIdeen.push(beitrag.text)
        }
    }
    //console.log(beitraegeList)
    //console.log(eigeneIdeen)
    const alleBeitraege = {
        Gerichte:beitraegeList, 
        Ideen:eigeneIdeen
    }
    //console.log(alleBeitraege)
    return alleBeitraege
}

function checkIfElementExistInArray(beitrag, beitraegeList){
    const gefundenesElement = beitraegeList.find(item => item.detail === beitrag.detail)
    if (gefundenesElement) {
        return true
    } else {
        //console.log('Beitrag existiert noch nicht und wird angelegt')
        return false
    }
}

export default createBeitraegeList


//TESTBEREICH
const beiträgetTest = [
    {
        _id: {
          $oid: "65dcb261391339e4ebc8a63f"
        },
        vorname: "Max",
        nachname: "Mustermann",
        category: "Salat",
        detail: "Nudelsalat",
        text: "Dies ist ein Beispiel aus der MongoDB"
    },
    {
        _id: {
          $oid: "65dcb261391339e4ebc8a63o"
        },
        vorname: "Max",
        nachname: "Mustermann",
        category: "Salat",
        detail: "Kartoffelsalat",
        text: "Dies ist ein Beispiel aus der MongoDB"
    },
    {
        _id: {
          $oid: "65dcb261391339e4ebc8a63l"
        },
        vorname: "Max",
        nachname: "Mustermann",
        category: "Dessert",
        detail: "Denkt euch was aus",
        text: "Ich bringe Muffins mit"
    },
    {
        _id: {
          $oid: "65dcb261391339e4ebc8a63r"
        },
        vorname: "Max",
        nachname: "Mustermann",
        category: "Salat",
        detail: "Kartoffelsalat",
        text: "Dies ist ein Beispiel aus der MongoDB"
    },
    {
        _id: {
          $oid: "65dcb261391339e4ebc8a63p"
        },
        vorname: "Max",
        nachname: "Mustermann",
        category: "Gebäck",
        detail: "Brot",
        text: "Dies ist ein Beispiel aus der MongoDB"
    },
    {
        _id: {
          $oid: "65dcb261391339e4ebc8a63l"
        },
        vorname: "Max",
        nachname: "Mustermann",
        category: "Eigene Ideen",
        detail: "Denkt euch was aus",
        text: "Ich bringe Chicken mit"
    },
]

function test(){
    createBeitraegeList(beiträgetTest)
}

test()
<!-- <script setup>
import Formular from '../components/Formular.vue';
import { ref } from 'vue';

const data= ref ({
  showSuccesfullPush: false,
  showFailedPush: false
})

defineProps({
  choosenCategory: {
    type: String, // Beachten Sie, dass wir hier den Typ auf String ändern
    required: true,
  },
  choosenDetail: {
    type: String,
    required: true
  }
})

</script>

<template>
    <Formular v-if="!data.showSuccesfullPush & !data.showFailedPush" :userCategory="choosenCategory" :userDetail="choosenDetail"/>
</template>

<style>
</style> -->

<script>
import Formular from '../components/Formular.vue';
//import { useRouter } from 'vue-router';
export default {
  data() {
  },
  components: {
    Formular
  },
  props: {
      choosenCategory: {
      type: String,
      required: true,
    },
    choosenDetail: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleFormSubmit (info) {
      //console.log(data)
      //console.log('handleFormSubmit', info.status)
      if(info.status === 201){
        this.$router.push("successful");
//        useRouter().push({
//          name: 'successful'
//        })
      } else {
        this.$router.push("failed");
//        useRouter().push({
//          name: 'failed'
//        })
      }
    }
  }
}
</script>

<template>
  <div>
    <Formular
      :userCategory="choosenCategory"
      :userDetail="choosenDetail"
    />
  </div>
</template>


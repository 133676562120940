<script>
//import { ref } from 'vue';
import { RouterLink } from 'vue-router';
import handelServerData from '../services/handelServerData'

//const emit = defineEmits(['formSubmitted']);

export default {
    data() {
      return {
        deinNameForm: "",
        textForm: ""
      }
    },
    props: {
      userDetail: {
        type: String,
        required: true,
      },
      userCategory: {
        type: String,
        required: true
      }
    },
    components: {
      RouterLink
    },
    methods: {
      onSubmit() {
        if (this.deinNameForm === '') {
          alert('Bitte gib Deinen Namen an, damit wir wissen, wer was mitbringt');
          return;
        }

        const info = {
          name: this.deinNameForm,
          category: this.userCategory,
          detail: this.userDetail,
          text: this.textForm
        }
      handelServerData.postCientData(info)
        .then(response => {
          // Verarbeite die Antwort
          console.log('Serverantwort:', response);
          // Hier kannst du weitere Aktionen ausführen, basierend auf der Serverantwort
          this.$router.push("/successful");
//          this.$emit('formSubmitted', response)
        })
        .catch(error => {
          // Handle den Fehler
          alert('Da hat irgendwas nicht geklappt ... versuchs nochmal bitte (' + error + ')');
          console.error('Fehler beim Senden des Formulars:', error);
          // Hier kannst du auf den Fehler reagieren, z.B. dem Benutzer eine Fehlermeldung anzeigen
          });
        }
    }
  }

//const deinNameForm = ref('')
//const textForm = ref('');


</script>

<template>
  <h1>Du hast "{{ userDetail }}" gewählt.</h1>
  <p></p>
  <form class="info-form" @submit.prevent="onSubmit">
    <div class="Input">
      <div class="nameInput">
        <label for="name">Dein Name:</label>
        <input id="name" v-model="deinNameForm">
      </div>
      <div class="textInput">
        <label for="review">Anmerkungen wenn Du möchtest:</label>      
        <textarea id="review" v-model="textForm"></textarea>
      </div>
    </div>
    <div class="buttonArea">
      <div class="submitButton">
        <button class="button" type="submit">Send</button>
        <!-- <input class="button" type="submit" value="Send"> -->
      </div>
      <RouterLink :to="{ name: 'auswahl'}">Abbrechen</RouterLink>
    </div>
  </form>
</template>

<style scoped>
  h3,
  p{
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    font-weight: bold;
    color: #2c3e50;
  }

  .Input {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nameInput,
  .textInput {
    width: 100%;
    display: flex;
    align-items: center; /* Vertikal zentrieren */
    justify-content: center;
    flex-direction: row;
    padding-top: 2rem;
  }

  label {
    display: block;
    align-items: center; /* Vertikal zentrieren */
    justify-content: flex-end; /* Text nach rechts ausrichten */
    text-align: right;
    width: 10%;
    padding-right: 1rem;
  }

  #name,
  #review {
    width: 25%;
    
    padding: 5px 12px;
    font-size: 14px;
    line-height: 20px;
    color: #24292e;
    vertical-align: middle;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: right 8px center;
    border: 1px solid #e1e4e8;
    border-radius: 6px;
    outline: none;
    box-shadow: rgba(225, 228, 232, 0.2) 0px 1px 0px 0px inset;           
  }

  #name:focus,
  #review:focus {
    border-color: #42b983;
    outline: none;
    box-shadow: rgba(4, 221, 94, 0.3) 0px 0px 0px 1px;
  }

  .buttonArea{
    padding-top: 2rem;
  }
  .submitButton{
    margin-bottom: 2rem;
  }
  .submitButton .button {
    width: 20%;
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 1;
    border-radius: 500px;
    transition-property: background-color,border-color,color,box-shadow,filter;
    transition-duration: .3s;
    border: 1px solid transparent;
    letter-spacing: 2px;
    min-width: 160px;
    text-transform: uppercase;
    white-space: normal;
    font-weight: 700;
    text-align: center;
    padding: 17px 48px;
    color: #fff;
    background-color: #42b983;
    height: 48px;
  }

  .submitButton .button:hover{
    transform: scale(1.04);
    background-color: #1ED760;
  }


</style>
